(function($) {

    var counterObject;
    counterObject = document.getElementById('counter');

    if( !counterObject ) {
        return;
    }

    var counter = counterObject.getContext('2d');
    var no = 0; // Starting Point
    var pointToFill = 4.72;  //Point from where you want to fill the circle
    var cw = counter.canvas.width;  //Return canvas width
    var ch = counter.canvas.height; //Return canvas height
    var diff;   // find the different between current value (no) and trageted value (100)

    function fillCounter( percentage ){
        no = percentage;

        diff = ((no/100) * Math.PI*2*10);

        counter.clearRect(0,0,cw,ch);   // Clear canvas every time when function is call

        counter.lineWidth = 5;     // size of stroke

        counter.fillStyle = '#383d43';     // color that you want to fill in counter/circle

        counter.strokeStyle = '#1990ff';    // Stroke Color

        counter.textAlign = 'center';

        counter.font = "700 20px AvertaStd san-serif";    //set font size and face

        counter.fillText(no+'%',100,110);       //fillText(text,x,y);

        counter.beginPath();

        counter.arc(cw/2,ch/2,(cw/2)-counter.lineWidth,pointToFill,diff/10+pointToFill);    //arc(x,y,radius,start,stop)

        counter.stroke();   // to fill stroke

        // now add condition

        if(no >= 100)
        {
            //clearTimeout(fill);     //fill is a variable that call the function fillcounter()
        }
        //no++;
    }

    //now call the function

    //var fill = setInterval(fillCounter,50);     //call the fillCounter function after every 50MS

    /* BEGIN Estimate Reading Time for Current Page */
    var $article = $( '#main' );
    var masthead = $( '#masthead' );
    var postInformation = $( '#post-information' );
    var remainingTime = $( '#remaining' );
    var remainingInformation = $( '#remaining-description');

    postInformation.css( 'left', ($article.offset().left - 16) );
    postInformation.css( 'margin-top', masthead.offset().top );

    if ( $article.height() ) {
        var calc = estimate.element( $article[ 0 ] );
        var total = [ '', Math.round( calc.total / 60 ), '' ].join( '' );

        remainingTime.html( Math.ceil( total ) );
        remainingInformation.html(' minutes left' );

        // requestAnimationFrame( function re () {
        var estimateReadingTime = function () {

            calc.update();
            var roundedRemaining = Math.round( calc.remaining / 60 * 10 ) / 10;
            fillCounter( (Math.ceil( roundedRemaining ) - Math.ceil(total) ) / Math.ceil( total ) * -100 );

            if ( roundedRemaining < 1 ) {
                roundedRemaining = ( calc.remaining === 1 ) ? [ '', calc.remaining, ' second left' ].join( '' ) : [ '', calc.remaining, ' seconds left' ].join( '' );
                remainingInformation.html( roundedRemaining );
            }
            else if ( roundedRemaining === 1 ) {
                remainingTime.html( roundedRemaining );
                remainingInformation.html( 'minute left' );
            }
            else {
                remainingTime.html( Math.ceil( roundedRemaining ) );
                remainingInformation.html( 'minutes left' );
            }

            // $( '#progress' )
            //     .attr( 'aria-valuenow', calc.progress )
            //     .css( 'width', [ calc.progress, '%' ].join( '' ) );

            // requestAnimationFrame( re );
        };

        /* BEGIN onScroll Event Handler */
        var setScrollEventHandler = function ( obj ) {
            $( document, window )
                .off( 'scroll' );

            if ( obj.isEnabled ) {
                $( document, window )
                    .on( 'scroll', function () {
                        estimateReadingTime();
                        isScrollAtTop();
                    });
            }
        };

        setScrollEventHandler( { 'isEnabled' : true } );
        /* END onScroll Event Handler */


        /* BEGIN isScrollAtTop() */
        var isScrollAtTop = function () {

            var offsetVideoComponent = 0;

            var offsetTop = $( this ).scrollTop();

            var autoPlayVideos = $( '[data-video="autoplay"]' );

            $.each( autoPlayVideos, function ( index, element ) {
                $ele = $( element );
                var offsetVideoTop = $ele.offset().top;
                if ( 0 < ( offsetVideoTop - offsetTop ) && ( offsetVideoTop - offsetTop ) < ( getWindowHeight() - $ele.height() ) ) {
                    $ele[0].play();
                }
                else {
                    $ele[0].pause();
                }
            });

        };
        isScrollAtTop();
        /* END isScrollAtTop() */
    }
    /* END Estimate Reading Time for Current Page */

})(jQuery);

(function($, window) {

    window.addEventListener("resize", resizeThrottler, false);

    var resizeTimeout;
    function resizeThrottler() {
        // ignore resize events as long as an actualResizeHandler execution is in the queue
        if ( !resizeTimeout ) {
            resizeTimeout = setTimeout(function() {
                resizeTimeout = null;
                actualResizeHandler();

                // The actualResizeHandler will execute at a rate of 15fps
            }, 66);
        }
    }

    function actualResizeHandler() {
        // handle the resize event
        var $article = $( '#main' );
        var masthead = $( '#masthead' );
        var postInformation = $( '#post-information' );

        postInformation.css( 'left', ($article.offset().left) );

        postInformation.css( 'margin-top', masthead.outerHeight() + 40 );
    }

}(jQuery, window));