(function() {

    //Return value for dark vs light mode status
    performance.mark('darkmode-check-start');
    function isDarkModeEnabled() {
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    }
    performance.mark('darkmode-check-end');

    //Set dark / light mode classes accordingly.
    performance.mark('darkmode-set-start');
    if( isDarkModeEnabled() ) {
        var bodyTag;
        bodyTag = document.querySelector('body');
        bodyTag.classList.add('dark-mode-enabled');
    }
    performance.mark('darkmode-set-end');

    function lockBody() {
        jQuery('body').addClass( 'lock-body' );
    }

    function unlockBody() {
        jQuery('body').removeClass( 'lock-body' );
    }

    //Global Modal Button Setup
    jQuery('#page').on('click', '[data-action="modal"]', function(e) {
        e.preventDefault();
        let thisTarget

        if(jQuery(this).data('target') !== '') {
            thisTarget = jQuery(`#${jQuery(this).data('target')}`);
        } else {
            thisTargetHref = jQuery(this).attr('href');
            thisTarget = jQuery(thisTargetHref);
        }

        //Add Universal Class to lock scrolling on body
        lockBody();
       thisTarget.addClass('open');
       thisTarget.find('a, input, select, [tab-index="0"]').first().focus();
    });

    //Global Modal Control Event
    jQuery('[data-action="modal-close"]').on( 'click', function(e) {
        e.preventDefault();

        let thisTarget
        thisTarget = jQuery(this).data('target');

        unlockBody();
        jQuery(`#${thisTarget}`).removeClass('open');

    });
})();