/**
 * File navigation.js.
 *
 * Handles toggling the navigation menu for small screens and enables TAB key
 * navigation support for dropdown menus.
 */
( function( $ ) {
	var container,
		mobileMenuContainer,
		mobileNavigation,
		mobileMenu,
		mobileBackBtn,
		searchOverlayOpenBtn,
		searchOverlayCloseBtn,
		searchOverlayContainer,
        mobileSearchBtn,
		mobileSearchView,
		button,
		closeButton,
		menu,
		links,
		mobileLinks,
		i,
		len;

	container = document.getElementById( 'site-navigation' );
	if ( ! container ) {
		return;
	}

	button = document.getElementById( 'mobile-menu-toggle' );
	if ( 'undefined' === typeof button ) {
		return;
	}

	mobileMenuContainer = document.getElementById( 'mobile-navigation-container' );
    if ( 'undefined' === typeof mobileMenuContainer ) {
        return;
    }

    mobileNavigation = document.getElementById( 'mobile-navigation' );
    if ( 'undefined' === typeof mobileNavigation ) {
        return;
    }

	closeButton = document.getElementById( 'mobile-menu-close' );
    if ( 'undefined' === typeof closeButton ) {
        return;
    }

    mobileBackBtn = document.getElementById( 'mobile-menu-back-btn' );
    if( 'undefined' === typeof  mobileBackBtn ) {
    	return;
	}

	mobileSearchBtn = document.getElementById( 'mobile-search-btn' );
    if( 'undefined' === typeof mobileSearchBtn ) {
    	return;
	}

    mobileSearchView = document.getElementById( 'mobile-search-view' );
    if( 'undefined' === typeof mobileSearchView ) {
        return;
    }

	menu = container.getElementsByTagName( 'ul' )[0];
    mobileMenu = mobileNavigation.getElementsByTagName( 'ul' )[0];

	// Hide menu toggle button if menu is empty and return early.
	if ( 'undefined' === typeof menu ) {
		button.style.display = 'none';
		return;
	}

	mobileNavigation.setAttribute( 'tabindex', '0' );

	menu.setAttribute( 'aria-expanded', 'false' );
	if ( -1 === menu.className.indexOf( 'nav-menu' ) ) {
		menu.className += ' nav-menu';
	}

	button.onclick = function() {
		if ( -1 !== mobileMenuContainer.className.indexOf( 'toggled' ) ) {
			mobileMenuContainer.className = mobileMenuContainer.className.replace( ' toggled', '' );
			button.setAttribute( 'aria-expanded', 'false' );
			menu.setAttribute( 'aria-expanded', 'false' );

		} else {
			mobileMenuContainer.className += ' toggled';
			button.setAttribute( 'aria-expanded', 'true' );
			menu.setAttribute( 'aria-expanded', 'true' );
            mobileNavigation.focus();
		}
	};

    closeButton.onclick = function() {
        if ( -1 !== mobileMenuContainer.className.indexOf( 'toggled' ) ) {
            mobileMenuContainer.className = mobileMenuContainer.className.replace( ' toggled', '' );
            button.setAttribute( 'aria-expanded', 'false' );
            menu.setAttribute( 'aria-expanded', 'false' );
            button.focus();

        } else {
            mobileMenuContainer.className += ' toggled';
            button.setAttribute( 'aria-expanded', 'true' );
            menu.setAttribute( 'aria-expanded', 'true' );

        }
    };

	// Get all the link elements within the menu.
	links = menu.getElementsByTagName( 'a' );

	// Each time a menu link is focused or blurred, toggle focus.
	for ( i = 0, len = links.length; i < len; i++ ) {
		links[i].addEventListener( 'focus', toggleFocus, true );
		links[i].addEventListener( 'blur', toggleFocus, true );
	}

    // Get all the mobile link elements within the menu.
    mobileLinks = mobileMenu.getElementsByTagName( 'a' );

    // Each time a menu link is focused or blurred, toggle focus.
    for ( i = 0, len = mobileLinks.length; i < len; i++ ) {
        mobileLinks[i].addEventListener( 'click', mobileItemClick, true );
        //mobileLinks[i].addEventListener( 'focus', mobileItemClick, true );
        //mobileLinks[i].addEventListener( 'blur', mobileItemClick, true );
    }

    //Back button event
	mobileBackBtn.addEventListener( 'click', mobileBackEvent, true );

    //Mobile Search Button
	mobileSearchBtn.addEventListener( 'click', mobileSearchEvent, true );

	/**
	 * Sets or removes .focus class on an element.
	 */
	function toggleFocus() {
		var self = this;

		// Move up through the ancestors of the current link until we hit .nav-menu.
		while ( -1 === self.className.indexOf( 'nav-menu' ) ) {

			// On li elements toggle the class .focus.
			if ( 'li' === self.tagName.toLowerCase() ) {
				if ( -1 !== self.className.indexOf( 'focus' ) ) {
					self.className = self.className.replace( ' focus', '' );
				} else {
					self.className += ' focus';
				}
			}

			self = self.parentElement;
		}
	}

	function mobileSearchEvent( event ) {
        mobileSearchView.className += ' show';
        mobileMenuContainer.className += ' mobile-searching';
	}

	function mobileBackEvent( event ) {
        mobileMenuContainer.className = mobileMenuContainer.className.replace(' deep', '');
        mobileMenuContainer.className = mobileMenuContainer.className.replace(' mobile-searching', '');
        button.setAttribute('aria-expanded', 'false');
        menu.setAttribute('aria-expanded', 'false');
        mobileSearchView.className = mobileSearchView.className.replace(' show', '');

        let mobileSubMenus = mobileMenuContainer.getElementsByClassName( 'sub-menu' );
        for ( let m = 0; m < mobileSubMenus.length; m++ ) {
        	mobileSubMenus[m].className = mobileSubMenus[m].className.replace( ' open', '' );
        }

        let mobileParentLink = mobileMenuContainer.querySelectorAll( '.clicked' );
        for ( i = 0; i < mobileParentLink.length; i++ ) {
            mobileParentLink[i].classList.remove('clicked');
        }
	}

	function mobileItemClick( event ) {
        event.preventDefault();
        event.stopPropagation();

        var self = this;
        self = self.parentElement;
        let thisSubMenu = self.getElementsByClassName( 'sub-menu' )[0];

        if( 'undefined' !== typeof thisSubMenu ) {

            /*
            Toggle Deep Class on mobile navigation to provide the status on 'in a sub menu'
             */
            if ( mobileMenuContainer.classList.contains( 'deep' ) ) {
                window.location.href = this.href;
            } else {
                mobileMenuContainer.className += ' deep';
                button.setAttribute('aria-expanded', 'true');
                menu.setAttribute('aria-expanded', 'true');

                if (-1 !== self.className.indexOf('clicked')) {
                    self.className = self.className.replace(' clicked', '');
                    thisSubMenu.className = thisSubMenu.className.replace(' open', '');
                } else {
                    self.className += ' clicked';
                    thisSubMenu.className += ' open';
                }

            }
        } else {
        	window.location.href = this.href;
        }
	}

	/**
	 * Toggles `focus` class to allow submenu access on tablets.
	 */
	( function( container ) {
		var touchStartFn, i,
			parentLink = container.querySelectorAll( '.menu-item-has-children > a, .page_item_has_children > a' );

		if ( 'ontouchstart' in window ) {
			touchStartFn = function( e ) {
				var menuItem = this.parentNode, i;

				if ( ! menuItem.classList.contains( 'focus' ) ) {
					e.preventDefault();
					for ( i = 0; i < menuItem.parentNode.children.length; ++i ) {
						if ( menuItem === menuItem.parentNode.children[i] ) {
							continue;
						}
						menuItem.parentNode.children[i].classList.remove( 'focus' );
					}
					menuItem.classList.add( 'focus' );
				} else {
					menuItem.classList.remove( 'focus' );
				}
			};

			for ( i = 0; i < parentLink.length; ++i ) {
				parentLink[i].addEventListener( 'touchstart', touchStartFn, {passive:true, capture:false} );
			}
		}
	}( container ) );

	/**
	 * Search Overlay
	 */
    searchOverlayOpenBtn = document.getElementById( 'search-overlay-open-btn' );
    if ( 'undefined' === typeof searchOverlayOpenBtn ) {
        return;
    }

    searchOverlayCloseBtn = document.getElementById( 'search-overlay-close-btn' );
    if ( 'undefined' === typeof searchOverlayCloseBtn ) {
        return;
    }

    searchOverlayContainer = document.getElementById( 'header-search-overlay' );
    if ( 'undefined' === typeof searchOverlayContainer ) {
        return;
    }

    searchOverlayOpenBtn.addEventListener( 'click', searchOverlayOpen, true );
    searchOverlayCloseBtn.addEventListener( 'click', searchOverlayClose, true );

	function searchOverlayOpen( event ) {
		event.preventDefault();
		event.stopPropagation();

        if( -1 !== searchOverlayContainer.className.indexOf( 'visible' ) ) {
            searchOverlayContainer.className = searchOverlayContainer.className.replace( ' visible', '' );
        } else {
            searchOverlayContainer.className += ' visible';
            let searchInput = document.querySelector( '#header-search-overlay .search-form .search-field' );
            searchInput.focus();
        }
	}

    function searchOverlayClose( event ) {
        event.preventDefault();
        event.stopPropagation();

        if( searchOverlayContainer.classList.contains( 'visible' ) ) {
            searchOverlayContainer.classList.remove( 'visible' );
        }
    }

    // BEGIN AlphaNumeric Key Listener
    $( document ).keydown( function ( e ) {

        if ( e.shiftKey && e.keyCode === 191 ) {
        }
        else if ( e.keyCode > 47 && e.keyCode < 91 && !e.metaKey && !e.ctrlKey ) {
            var eTarget = $( e.target );
            if ( !eTarget.is( 'input' ) && !eTarget.is( 'textarea' ) ) {
                var $searchOverlay = $( '#header-search-overlay' );
                var $searchInput = $( '.search-form .search-field' );

                var keyString = String.fromCharCode( e.keyCode );
                keyString = ( e.shiftKey ) ? keyString : keyString.toLowerCase();

                if ( !$searchOverlay.hasClass( 'visible' ) ) {
                    $searchOverlay.addClass( 'visible' );
                    // TODO: Fix Hack: Forcing lowercase temporarily to deal with the inappropriate return from the keyup event.
                    $searchInput.focus();

                    $( '[ data-target = "#header-search-overlay" ]' ).trigger( 'click' );
                }
                else {
                    $searchInput
                        .val( [ $searchInput.val(), keyString ].join( '' ) );
                }
            }
        }

    });
    /* END AlphaNumeric Key Listener */

} )( jQuery );

/*
Stick Menu
 */
window.addEventListener( 'load', function() {

    let unStickTheNav = function( direction ) {
        navigation.classList.add( 'out-of-view' );
    };

    let hideTheNav = function( direction ) {
        navigation.classList.add( 'hide' );
    };

    let stickTheNav = function( direction ) {
        navigation.classList.remove( 'out-of-view' );
    };

    let scrollPos = 0;

    let masthead = document.getElementById( 'masthead' );
    let navigation = document.getElementById( 'navigation-container' );
    let stickNav = new Waypoint.Inview({
        element: navigation,
        enter: function(direction) {
            // navigation.classList.remove( 'stuck' );
            // unStickTheNav(direction);
            // console.log('enter ' + direction);
        },
        entered: function(direction) {
            // navigation.classList.remove( 'stuck' );
            // unStickTheNav(direction);
            // console.log('header has entered ' + direction);
        },
        exit: function(direction) {
            // console.log( 'header has exit' + direction );
            //stickTheNav( direction );
        },
        exited: function(direction) {
            // console.log( 'header has exited ' + direction );
            // stickTheNav( direction );
        }
    });

    // adding scroll event
    window.addEventListener('scroll', function(){
        // detects new state and compares it with the new one
        if ( (document.body.getBoundingClientRect()).top < scrollPos ) {
            navigation.classList.add( 'stuck' );

        } else {
            // saves the new position for iteration.
            navigation.classList.remove( 'stuck' );
            scrollPos = (document.body.getBoundingClientRect()).top;
        }
    });

    /**
     * focuses on a form field element even if it has tabIndex
     * @param  {DOM object} item [description]
     */
    function focusMenuItem( item ){
        if( !item ){
            console.warn('no focusable item: ', item);
            return;
        }

        var savedTabIndex = item.getAttribute('tabindex');
        item.setAttribute('tabindex', '-1');
        item.focus();
    }

});
