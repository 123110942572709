(function() {
    let textBox,
        isPage;

    textBox = document.getElementById('textBox');
    if ('undefined' === typeof textBox || textBox == null ) {
        return;
    }

        isPage = window.location.href == ("https://www.portent.com/about", "https://www.portent.com/services");
        if (!isPage) {
            textBox.classList.remove('hasTextBox');
        } else {
            textBox.classList.add('hasTextBox');
        }
})();