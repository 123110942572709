(function($){
    let sidebar,
        masthead,
        readingIndicator,
        authorBio,
        footer,
        mainArticle;

    sidebar = document.getElementById( 'post-information' );
    masthead = document.getElementById( 'masthead' );
    readingIndicator = document.getElementById( 'reading-indicator');
    authorBio = document.getElementById( 'post-author-bio' );
    if( 'undefined' === authorBio || authorBio === null ) {
        authorBio = document.getElementById('comments');
    }
    mainArticle = document.querySelector( 'main > article' );
    footer = document.getElementById( 'colophon' );

    if( ! sidebar ) {
        return;
    }

    if( ! masthead ) {
        return;
    }

    if( ! readingIndicator ) {
        return;
    }

    sidebar.style.marginTop = masthead.offsetHeight+50+'px';

    var inview = new Waypoint.Inview({
        element: masthead,
        enter: function(direction) {
            sidebar.style.marginTop = masthead.offsetHeight+50+'px';
        },
        entered: function(direction) {
            sidebar.style.marginTop = masthead.offsetHeight+50+'px';
        },
        exited: function(direction) {
            sidebar.style.marginTop = '36px';
        }
    });

    var footerView = new Waypoint.Inview({
        element: authorBio,
        offset : '5%',
        enter: function(direction) {
            sidebar.classList.add('fade-out');
        },
        entered: function(direction) {
            sidebar.classList.add('fade-out');
        },
        exited: function(direction) {
            if(direction === 'up') {
                sidebar.classList.remove('fade-out');
            }
        }
    });

    var indicator = new Waypoint.Inview({
        element: mainArticle,
        exited: function(direction) {
            readingIndicator.classList.add( 'hidden' );
        },
        entered: function(direction) {
            readingIndicator.classList.remove( 'hidden' );

        },
        enter: function(direction) {
            readingIndicator.classList.remove( 'hidden' );

        }
    });

})(jQuery);