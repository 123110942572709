(function() {
    let presentationSlides = document.querySelectorAll('.slide__content');

    presentationSlides.forEach( function(elem, index) {
        console.log( 'new slide' );
        let slideImage = document.querySelector(`[data-slide-id="${elem.id}"]`);
        new Waypoint.Inview({
            element: elem,
            offset:'50%',
            entered: function(direction) {
                elem.classList.add('inview');
                elem.classList.remove('exited');
                slideImage.classList.add('inview');
                slideImage.classList.remove('exited');
                console.log('slide in view');
            },
            exited: function(direction) {
                elem.classList.add('exited');
                elem.classList.remove('inview');
                slideImage.classList.add('exited');
                slideImage.classList.remove('inview');
                console.log( 'slide leaving view' );
            }
        });
    });
})();