!function(e){if("object"==typeof exports&&"undefined"!=typeof module)module.exports=e();else if("function"==typeof define&&define.amd)define([],e);else{var f;"undefined"!=typeof window?f=window:"undefined"!=typeof global?f=global:"undefined"!=typeof self&&(f=self),f.estimate=e()}}(function(){var define,module,exports;return(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);throw new Error("Cannot find module '"+o+"'")}var f=n[o]={exports:{}};t[o][0].call(f.exports,function(e){var n=t[o][1][e];return s(n?n:e)},f,f.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(_dereq_,module,exports){module.exports=function(){if(window.pageYOffset)return window.pageYOffset;return document.documentElement.clientHeight?document.documentElement.scrollTop:document.body.scrollTop;};},{}],2:[function(_dereq_,module,exports){'use strict';var scrolltop=_dereq_('scrolltop');var defaults={speed:150,spaces:/\W+/g};function resolveOptions(options){var no;var o=options;if(o===no){return defaults;}
        if(o.speed===no){o.speed=defaults.speed;}
        if(o.spaces===no){o.spaces=defaults.spaces;}
        return options;}
        function measureText(text,options){var o=resolveOptions(options);var words=text.split(o.spaces).length;var seconds=Math.round(words/o.speed*60);return seconds;}
        function measureElement(element,options){var calc={initialize:initialize,update:update};function initialize(){calc.total=measureText(element.innerText||element.textContent,options);}
            function update(){calc.progress=getProgress();calc.remaining=Math.ceil(calc.total*(1-(calc.progress/100)));}
            function getProgress(){var scroll=scrolltop();var rect=element.getBoundingClientRect();var diffTop=scroll-element.offsetTop+window.innerHeight/2;var diffHeight=rect.height;var scaled=diffTop/diffHeight*100;var bounded=Math.max(0,Math.min(scaled,100));return bounded;}
            initialize();update();return calc;}
        module.exports={element:measureElement,text:measureText};},{"scrolltop":1}]},{},[2])
(2)});