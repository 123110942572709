(function($) {

    let mobileCategorySelect;
    mobileCategorySelect = document.getElementById( 'mobile-category-selector' );

    if( 'undefined' !== typeof mobileCategorySelect && mobileCategorySelect !== null ) {
        mobileCategorySelect.addEventListener('change', function (event) {
            window.location.href = event.target.value;
        });
    }

})(jQuery);